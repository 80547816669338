import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ArticleLink from '../components/article-link';

import './author.scss';

const Page = ({ data }: any) => {
  const {
    author: {
      name, description, authorDetails: { authorImage },
    },
    posts,
  } = data;
  const image = getImage(authorImage?.localFile);
  return (
    <Layout>
      <SEO
        title={`Skin Deep Author: ${name}`}
      />
      <article className="article">
        <div className="article-inner">
          <div className="article-wrapper">
            <h2 className="article-title">{name}</h2>
          </div>
          <div className="author-page-image">
            <GatsbyImage image={image} alt="" />
          </div>
          <div className="article-wrapper">
            <div className="article-content author-description" dir="auto">
              {description}
            </div>
          </div>
          <h3 className="article-related-items-header">
            {`${name}'${name.endsWith('s') ? '' : 's'} articles`}
          </h3>
          <div className="article-related-items">
            {posts.edges.map(({
              node: {
                title, date, slug, featuredImage,
              },
            } : any) => (
              <ArticleLink
                title={title}
                imageData={featuredImage?.node?.localFile}
                slug={slug}
                date={date}
                key={slug}
              />
            ))}
          </div>
        </div>
      </article>
    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
query AuthorById(
  $id: String!
) {
  # selecting the current post by id
  author: wpUser(id: { eq: $id }) {
    name
    description
    url
    authorDetails {
      authorImage {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 350
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              aspectRatio: 1
            )
          }
        }
      }
    }
  }
  posts: allWpPost(filter: {author: {node: {id: {eq: $id}}}}, limit: 16) {
    edges {
      node {
        title
        date(formatString: "DD.MM.YY")
        slug
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 314
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  aspectRatio: 1.7375
                )
              }
            }
          }
        }
      }
    }
  }
}
`;
